.gallery {
    padding: 2rem;
    font-family: "Roboto", serif;
    text-align: center;
}

.gallery-title {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #282667;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columnas en pantallas grandes */
    gap: 1.5rem;
}

.gallery-item {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra ligera */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-item:hover {
    transform: scale(1.05); /* Efecto de zoom */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.gallery-image {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover; /* Ajusta la imagen para que no se deforme */
}

/* Media query para pantallas pequeñas */
@media (max-width: 768px) {
    .gallery-grid {
        grid-template-columns: 1fr; /* 1 columna en pantallas pequeñas */
    }
}
